<template>
  <v-layout flex justify-center wrap>
    <v-flex sm12 md6>
      <v-card light tag="section">
        <v-card-text>
          <v-alert
            v-if="success"
            border="right"
            colored-border
            type="info"
            elevation="2"
          >
            Готово
          </v-alert>
        </v-card-text>

        <v-card-actions align-center>
          <v-btn
            v-if="!success"
            :loading="inProcess"
            color="red"
            class="ma-2 white--text"
            :disabled="inProcess"
            @click="submit"
          >
            Перенести
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  import api from '../../services/feedback'

  export default {
    data () {
      return {
        inProcess: false,
        success: false,
      }
    },
    methods: {
      async submit () {
        this.inProcess = true

        await api.approve(this.$route.params.id)
        this.success = true
      }
    }
  }
</script>
